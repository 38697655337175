import React from 'react';
import { fluidImage } from '../../util/fluidImage';

import { graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    PageList,
    PagePreview,
    Content,
    IntroQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../components/InnerPage';

const WoranBahaiGlauben = ({ data }) => (
    <InnerPage
        title="Woran Bahá&#8217;í glauben"
        description="Bahá’u’lláh und Sein Anspruch Bahá’u’lláh und Sein Anspruch als Überbringer"
    >
        <IntroHolder
            title="Woran Bahá&#8217;í glauben"
            picture={data.introImage}
            breadcrumbs={{
                'current-item': 'Woran Bahá&#8217;í glauben',
            }}
        >
            <IntroQuote>
                Der ist wirklich ein Mensch, der sich heute dem Dienst am ganzen
                Menschengeschlecht hingibt.
            </IntroQuote>
        </IntroHolder>
        <TwoColumns>
            <Content>
                <PageList>
                    <PagePreview
                        href="/woran-bahai-glauben/bahaullah-und-sein-anspruch/"
                        title="Bahá’u’lláh und Sein Anspruch"
                        description="Bahá’u’lláh und Sein Anspruch als Überbringer einer göttlichen Botschaft"
                        imageSource={
                            data.bahaullahUndSeinAnspruchFeature.childImageSharp
                                .fluid.src
                        }
                    />
                    <PagePreview
                        href="/woran-bahai-glauben/natur-des-menschen/"
                        title="Die Natur des Menschen"
                        description="Die Natur des Menschen ist eine mit Vernunft begabte Seele"
                        imageSource={
                            data.dieNaturDesMenschenFeature.childImageSharp
                                .fluid.src
                        }
                    />
                    <PagePreview
                        href="/woran-bahai-glauben/gott-und-der-mensch/"
                        title="Gott und der Mensch"
                        description="Einheit, Fortschreitende Offenbarung und Kultur als Prozess"
                        imageSource={
                            data.gottUndDerMenschFeature.childImageSharp.fluid
                                .src
                        }
                    />
                    <PagePreview
                        href="/woran-bahai-glauben/der-einzelne-und-die-gesellschaft/"
                        title="Der Einzelne und die Gesellschaft"
                        description="Eine wechselseitige Beziehung auf dem Weg zur Weltkultur"
                        imageSource={
                            data.derEinzelneUndDieGesellschaftFeature
                                .childImageSharp.fluid.src
                        }
                    />
                </PageList>
                <Reference>
                    <p>
                        1. Bahá&#8217;u&#8217;lláh, Botschaften aus Akká 11:13
                    </p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default WoranBahaiGlauben;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: { eq: "woran-bahai-glauben-feature.jpg" }
        ) {
            ...fluidImage
        }
        bahaullahUndSeinAnspruchFeature: file(
            relativePath: { eq: "bahaullah-und-sein-anspruch-feature.jpg" }
        ) {
            ...pagePreviewImage
        }
        gottUndDerMenschFeature: file(
            relativePath: { eq: "gott-und-der-mensch-feature.jpg" }
        ) {
            ...pagePreviewImage
        }
        derEinzelneUndDieGesellschaftFeature: file(
            relativePath: {
                eq: "der-einzelne-und-die-gesellschaft-feature.jpg"
            }
        ) {
            ...pagePreviewImage
        }
        dieNaturDesMenschenFeature: file(
            relativePath: {
                eq: "Kachel_Die-Natur-des-Menschen-a-e1487613372304.jpg"
            }
        ) {
            ...pagePreviewImage
        }
    }
`;
